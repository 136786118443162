<template>
  <main>
    <!-- bg gradient -->
    <div class="bg-header"
         :class="{ 'short-bg-header': displayBackgorund, 'top-webview' : checkWebview(), 'padding-bg-regis': isHasCaution}"></div>

    <!-- content page -->
    <div class="container home-content"
         :class="{'padding-webview' : checkWebview(), 'padding-has-caution': isHasCaution}">
      <!-- menu left -->
      <MenuLeft class="pc" />

      <!-- content right -->
      <div class="main-content">
        <!-- content tab flow menu left -->
        <RouterView />
      </div>
    </div>
  </main>
</template>

<script>
import MenuLeft from '@/components/profile/MenuLeft'
import store from '@/store'
import Common from '@/mixins/common.mixin'
import Cookie from 'js-cookie'
import { PAGE_NATIVE } from '@/enum/title-header'
import { mapState } from 'vuex'

export default {
  name: 'Index',

  components: { MenuLeft },

  mixins: [Common],

  beforeRouteEnter (to, from, next) {
    store.dispatch('user/infoStatisticCommon').then(_ => next())
  },

  computed: {
    displayBackgorund () {
      // page has background different
      const arrRoute = ['Profile.News.List', 'Profile.News.Detail', 'Profile.FavoriteAds', 'Profile.CartList', 'Profile.DeleteUser', 'Profile.MailSetting']
      return arrRoute.includes(this.$route.name)
    },
    ...mapState('auth', ['profile']),
    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    }

  },

  created () {
    if (this.checkWebview()) {
      if (!Cookie.get('bearer_token')) {
        this.openPageNative('Login')
      } else {
        PAGE_NATIVE.forEach((elm) => {
          if (elm.path === this.$route.path) {
            this.openPageNative(elm.name)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
main {
  position: relative;
  .has-modal & {
    @media #{$info-phone} {
      position: initial;
    }
  }
  .bg-header {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 326px;
    background-image: url("../../assets/images/mypage-header.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    &.short-bg-header {
      height: 193px;
      background-image: url("../../assets/images/header-news.svg");
      @media #{$info-phone} {
        background-image: url("../../assets/images/header-news-sp.png");
        top: 45px;
        height: 140px;
      }
    }
    &.padding-bg-regis {
      @media #{$info-phone} {
        top: 91px;
      }
    }
    &.top-webview {
      top: 0;
    }
    @media #{$info-phone} {
      background-image: url("../../assets/images/mypage-header-sp.png");
      height: 246px;
      top: 45px;
    }
  }
  .home-content {
    z-index: 1;
    padding-top: 72px;
    padding-bottom: 96px;
    .has-modal & {
      @media #{$info-phone} {
        position: initial;
      }
    }
    @media #{$info-phone} {
      padding-top: 76px;
      padding-bottom: 50px  ;
    }
    &.padding-has-caution {
    @media #{$info-phone} {
      padding-top: 120px;
    }
  }
    &.padding-webview {
      padding-top: 35px;
    }
    .main-content {
      margin-left: 287px;
      min-height: 500px;
      @media #{$info-tablet-vertical} {
       margin-left: 253px;
      }
      @media #{$info-phone} {
        margin-left: 0;
        min-height: unset;
      }
    }
  }
}

</style>
